import React from "react"
import styled from "@emotion/styled"
import Project from "../projects/project"
import Flex from "../projects/templateflex"
import Image from "../components/image"
import { Helmet } from "react-helmet"

const Title = styled.h2`
  color: #004f87;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 20px;
  flex: 0 0 95%;
  margin-left: 2.5%;
`
const SubTitle = styled.h3`
  color: #004f87;

  font-size: 20px;
  flex: 0 0 20%;
`
const Desc = styled.p`
  flex: 0 0 95%;
  margin-left: 2.5%;
  margin-bottom: 30px;
`
const Text = styled.p`
  height: auto;
`
const TextBox = styled.div`
  flex: 0 0 42.5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(6) {
    order: 6;
  }
  &:nth-child(7) {
    order: 7;
  }
  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(6) {
      order: 5;
    }
  }
`

const Img = styled.div`
  border: 0.01px #dcdcdc solid;
  box-shadow: 0px 18px 25px rgba(0, 0, 0, 0.15);
  flex: 0 0 42.5%;
  margin-bottom: 30px;
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 5;
  }
  &:nth-child(8) {
    order: 8;
  }

  @media (max-width: 760px) {
    flex: 0 0 95%;
    margin-left: 2.5%;
    &:nth-child(5) {
      order: 6;
    }
  }
`
const Stasis = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Portfolio- Stasis Rehab</title>
      <link rel="canonical" href="https://avtar.dev/stasisrehab" />
    </Helmet>
    <Project>
      <Flex>
        <Title>Stasis Rehab</Title>
        <Desc>
          This project is a website for a physiotherapy rehab clinic based in
          Langley Township, British Columbia. The founders were looking for a
          better content management solution because the website builder they
          were using was too limited for their needs. They also wanted to
          freshen up the visual design in comparison to the templates provided
          by website builders.
        </Desc>
        <TextBox>
          <SubTitle>Gatsby Integration</SubTitle>
          <Text>
            I built the website using the Gatsby framework. The resource base is
            full of helpful documentation and minimized the learning curve for
            using a new React framework. The plugins and GraphQL data layer also
            helped optimize things like image responsiveness and load times.
          </Text>
        </TextBox>
        <Img>
          <Image
            fileName="stasis-1.png"
            style={{ width: "100%" }}
            alt="stasis home page"
          />
        </Img>
        <Img>
          <Image
            fileName="stasis-2.png"
            style={{ width: "100%" }}
            alt="contenful graphQL image"
          />
        </Img>
        <TextBox>
          <SubTitle>Contentful CMS</SubTitle>
          <Text>
            One of the client’s requirements was a blog component that they can
            easily update themselves. I figured a service like Contenful would
            work and it did much better than I expected. Gatsby’s Node API
            paired with the service made it simple to create dynamic blog pages.
            It also enables my clients to change the business hours, pricing
            strucure, and more importantly issue updates about COVID.
          </Text>
        </TextBox>
        <TextBox>
          <SubTitle>Future Improvements</SubTitle>
          <Text>
            I hope to add some SVG animations to improve interaction with users.
            This website also heavily relies on its SEO component so
            improvements to that will be ongoing. Another component that the
            founders have expressed interest in is a better scheduling system
            for clients to book appointments.
          </Text>
        </TextBox>
        <Img>
          <Image
            fileName="stasis-3.png"
            style={{ width: "100%" }}
            alt="future svg animations"
          />
        </Img>
      </Flex>
    </Project>
  </>
)

export default Stasis
